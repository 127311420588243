/* global config, angular, moment, PDFJS, Promise, parseInt, FormData, gData */

'use strict';

angular.module('managerApp').controller('DemarchesAntsController', (
        $scope, $rootScope, $route, $routeParams, $location, $timeout, $cookies, $mdDialog,
        toast, procedureService, procedureTools, Auth, terminals, comments) => {

    const path = $location.path();
    const defaultErrorMessage = 'Une erreur est survenue lors du traitement de votre demande.';

    $scope.config = config;
    $scope.gData = gData;
    $scope.currentUser = $rootScope.currentUser;
    $scope.statuses = $scope.gData.statuses;

    $scope.messages = procedureTools.messages;
    $scope.hasTMSPermission = Auth.hasPermissions('TMS');
    $scope.hasProcedureEditPermission = Auth.hasPermission('SERVICE_CERTIFICAT_IMMATRICULATION', 'PUT');

    $scope.notify = null;
    $scope.refund = null;
    $scope.client = null;

    $scope.procedure = null;


    // init
    console.log($location.search().type);
    $scope._type = $location.search().type ? $location.search().type : 'certificat-immatriculation';

    $scope.procedureId = $routeParams.id;
    $scope.isItem = typeof $scope.procedureId !== 'undefined';

    // for item
    $scope.procedure = null;
    $scope.client = null;
    $scope.seller = null;

    $scope.vehicle = null;
    $scope.terminal = null;
    $scope.isTerminalProcedure = false;
    $scope.item = null;
    $scope.origin = null;
 

    // for list
    $scope.proceduresCount = null;
    $scope.procedures = [];

    // $scope.documentTypes = procedureTools.getDocumentTypes();
    $scope.isOldProcedure = false;

    let searchItemsPerPage = $cookies.get('pagination.procedures.itemsPerPage') || 25;
    let maxDate = new Date();
    maxDate.setHours(23, 59, 59);
    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: searchItemsPerPage,
        totalItems: 0,
        maxDate: maxDate,
        filters: {},
        pageChanged: (loadData) => {
            $cookies.put('pagination.procedures.itemsPerPage', $scope.pagination.itemsPerPage);
            if (loadData === true) {
                getProcedures();
            }
        }
    };

    $scope.runSearch = () => {
        let url = updateCurrentURL();
        if (url && url.search !== '') {
            window.location = url.href;
        }
    };
    $scope.resetSearch = () => {
        let url = new URL(window.location);
        if (url.search !== '') {
            url.search = '';
            window.location = url;
        }
    };

    function updateCurrentURL(reset = false) {
        const url = new URL(window.location);
        if (reset === true) {
            url.search = '';
            return url;
        }
        if ($scope.pagination.filters) {
            let filters = $scope.pagination.filters;

            $cookies.put('showCompletedProcedures', JSON.stringify(filters));

            for (let i in filters) {
                if (i === 'date_from' || i === 'date_to') {
                    url.searchParams.set(i, moment(filters[i]).format(config.date.defaultFormat));
                } else if (i === 'DiscountCode') {
                    if (filters.DiscountCode !== null) {
                        url.searchParams.set('discountCode', filters.DiscountCode._id);
                    }
                } else if (i === 'paidOnly' || i === 'completed') {
                    url.searchParams.set(i, filters[i] === true ? 1 : 0);
                } else {
                    url.searchParams.set(i, filters[i]);
                }
            }
        }
        return url;
    }

    function getSearchFilters() {
        let search = $location.search();
        for (let i in search) {
            if (i === 'paidOnly' || i === 'completed') {
                $scope.pagination.filters[i] = (search[i] + '') === '1';
                $scope.showCompletedProcedures = (search.completed + '') === '1';
                $scope.showPaidOnlyProcedures = (search.paidOnly + '') === '1';
            } else if (i === 'date_from' || i === 'date_to') {
                $scope.pagination.filters[i] = new Date(search[i]);
            } else {
                $scope.pagination.filters[i] = search[i];
            }
        }
    }

    function getTerminalPartners() {
        let params = {
            partners: 1
        };
        let data = JSON.parse(localStorage.getItem("terminalPartners"));
        if (!data) {
            terminals.get(params).then((data) => {
                localStorage.setItem('terminalPartners', JSON.stringify(data));
                $scope.terminalPartners = data.items;
                $scope.$apply();
            }).catch((err) => {
                $scope.error = err;
            });
        } else {
            $scope.terminalPartners = data.items;
        }
        if ($scope.terminalPartners && $scope.terminalPartners[0] && $scope.terminalPartners[0].Client._id !== 'all') {
            $scope.terminalPartners.unshift({
                _id: 'all',
                Client: {
                    company: 'Tous les partenaires'
                }
            });
        }
    }

    function getVehicleTypes() {
        let vehicleTypes = JSON.parse(localStorage.getItem('vehicleTypes'));
        if (!vehicleTypes) {
            procedureService.getVehicleTypes().then((data) => {
                localStorage.setItem('vehicleTypes', JSON.stringify(data));
                $scope.vehicleTypes = data;
                $scope.$apply();
            }).catch((err) => {
                $scope.error = err;
            });
        } else {
            $scope.vehicleTypes = vehicleTypes;
        }
        if ($scope.vehicleTypes && $scope.vehicleTypes[0] && $scope.vehicleTypes[0].id !== 'all') {
            $scope.vehicleTypes.unshift({
                id: 'all',
                typeVehicule: 'Tous les genres'
            });
        }
    }

    function getTerminals() {
        let _terminals = JSON.parse(localStorage.getItem('terminals'));
        if (!_terminals) {
            terminals.getSimple().then((data) => {
                localStorage.setItem('terminals', JSON.stringify(data));
                $scope.terminalsItems = data;
                $scope.$apply();
            }).catch((err) => {
                $scope.error = err;
            });
        } else {
            $scope.terminalsItems = _terminals;
        }
        if ($scope.terminalsItems && $scope.terminalsItems[0] && $scope.terminalsItems[0]._id !== 'all') {
            $scope.terminalsItems.unshift({
                _id: 'all',
                name: 'Toutes les bornes'
            });
        }
    }

    function getServicesCount() {
        if ($scope.hasProceduresPermissions) {
            procedureService.getCounts().then((data) => {
                const counts = data.counts || [];
                for (let i = 0; i < counts.length; i++) {
                    const row = counts[i];
                    switch (row.type) {
                        case 'certificat-immatriculation':
                            //$scope.antscim = row.count;
                            $scope.antscim = 2100;
                            break;
                        case 'vente-en-ligne':
                            $scope.antsvel = row.count;
                            $scope.antsvel = 450;
                            break;
                        case 'declaration-cession':
                            // $scope.antsdc = row.count;
                            $scope.antsdc = 562;
                            break;
                        case 'changement-adresse':
                            // $scope.antsca = row.count;
                            $scope.antsca = 350;
                            break;
                        case 'duplicata':
                            // $scope.antsdu = row.count;
                            $scope.antsdu = 14;
                            break;
                    }
                }
                $scope.$apply();
            });
        }
    }
    $scope.showCompletedProceduresOnChange = () => {
        //$cookies.put('showCompletedProcedures', $scope.showCompletedProcedures);
        $scope.pagination.filters.completed = $scope.showCompletedProcedures === true;
        getProcedures();
    };
    $scope.showPaidOnlyProceduresOnChange = () => {
        //$cookies.put('showPaidOnlyProcedures', $scope.showPaidOnlyProcedures);
        $scope.pagination.filters.paidOnly = $scope.showPaidOnlyProcedures === true;
        getProcedures();
    };




    $scope.handleUnhandle = (procedureId, unhandle = false, isFromList = false) => {
        procedureService.handleUnhandle(procedureId, unhandle, isFromList).then((response) => {
            toast.show(
                    unhandle ?
                    'La prise en charge de cette démarche a bien été arrêtée.' :
                    'La démarche a bien été prise en charge. ', 'success', true, 10000);
            $location.search({});
            $route.reload();
        }).catch((err) => {
            toast.show(err && err.message ? err.message :
                    defaultErrorMessage, 'error', true, 10000);
        });
    };

    function getProcedures() {
        let params = {
            type: $scope._type
        };
        if ($scope.pagination.filters) {
            let filters = $scope.pagination.filters;
            for (let i in filters) {
                if (i === 'date') {
                    params[i] = moment(filters[i]).format(config.date.defaultFormat);
                } else if (i === 'DiscountCode') {
                    if (filters.DiscountCode !== null) {
                        params.discountCode = filters.DiscountCode._id;
                    }
                } else if (i === 'paidOnly' || i === 'completed') {
                    params[i] = filters[i] === true ? 1 : 0;
                } else {
                    params[i] = filters[i];
                }
            }
        }
        procedureService.get(params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            $scope.pagination.params = params;
            $scope.proceduresCount = data.count;
            $scope.procedures = data.items;
            for (let i = 0; i < $scope.procedures.length; i++) {
                let procedure = $scope.procedures[i];
                procedure.origin = procedureTools.getOrigin(procedure);
                procedure.isHandled = procedureTools.isHandled(procedure);
                procedure.isHandledByUser = procedure.UserId === $rootScope.currentUser._id;
                switch (params["type"]) {
                    case 'certificat-immatriculation':
                        item = procedure.CertificatImmatriculation;
                        break;
                    case 'vente-en-ligne':
                        item = procedure.VenteEnLigne;
                        break;
                    case 'declaration-cession':
                        item = procedure.DeclarationCession;
                        break;
//                            case 'changement-adresse':
//                            let item = procedure.CangementAdresse;
//                                break;
//                            case 'duplicata':
//                            let item = procedure.Duplicata;
//                                break;
                        procedure.isSentToTMS = item.status === 'sent-to-tms';
                }
                // let item = procedure.CertificatImmatriculation;


                procedure.completed = procedure.status === 'completed';
                procedure.pending = procedure.status === 'pending';
                procedure.isRecentlyCreated = false;
                procedure.isRecentlyUpdated = false;
                procedure.isReadyForProcessing =
                        procedure.pending &&
                        !procedure.isSentToTMS &&
                        procedure.signed === true &&
                        procedure.documentsUploaded === true &&
                        procedure.waitingForDocuments === false;
                console.log(procedure);
                if (procedure.isReadyForProcessing) {
                    let createdAt = moment(procedure.createdAt),
                            updatedAt = moment(procedure.updatedAt),
                            _3Days = moment().subtract(1, 'days'),
                            hasAtLeast3Days = createdAt.isSameOrBefore(_3Days),
                            updatedRecently = updatedAt.isSameOrAfter(_3Days);

                    if (hasAtLeast3Days && updatedRecently &&
                            procedure.signed === true &&
                            procedure.documentsUploaded === true &&
                            procedure.waitingForDocuments === false) {
                        procedure.isRecentlyUpdated = true;
                    } else {
                        procedure.isRecentlyCreated = true;
                    }
                }
            }
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        });
    }

    $scope.openMenu = function ($mdMenu, e) {
        $mdMenu.open(e);
    };

    //------------------------------------------------------------------------------
    
    getSearchFilters();
    getProcedures();
    getServicesCount();
    getTerminals();
    getTerminalPartners();
    getVehicleTypes();

});